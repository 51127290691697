import * as React from "react";
import {withTranslation} from "react-i18next";
import {Badge, Button, Col, Form} from "react-bootstrap";
import * as yup from "yup";
import {Formik} from "formik";
import * as wordcount from "wordcount";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faQuestionCircle} from '@fortawesome/free-solid-svg-icons'
import ListGroup from "react-bootstrap/ListGroup";
import Constants from "../../../constants";
import * as axios from "axios";
import * as utils from "../../../utils/language";
import Colors from "../../../style/Colors";

class Step3 extends React.Component {

    constructor(props) {
        super(props);

        this.prev = this.props.prev;
        this.next = this.props.next;
        this.updateFields = this.props.updateFields;

        this.docSchema = yup.object({
            docName: yup.string().min(2).required(),
            docContent: yup.string().min(10).required(),
        });

        this.schema = yup.object({
            lang: yup.string().min(2).required(),
            documentListSize: yup.number().min(1).required()
        });

        this.state = {
            submitAlreadyClick: false,
            documentSubmitAlreadyClick: false,
            documentList: [],
            prices: {},
            currency: "EUR"
        };

        this.languages = ["EN", "FR", "DE", "ES"];
        this.document = [];
        this.currencySymbol = Constants.currencySymbol;
        this.langSelected = [];
    }

    back = () => {
        this.prev();
    }

    addDocument = (fields, {resetForm}) => {
        const {t} = this.props;

        const name = fields.docName;
        const nbWords = wordcount(fields.docContent);
        const content = fields.docContent;
        const label = nbWords + " " + t("REGISTER_STEP3_WORD") + " : " + name;

        this.document.push({
            title: fields.docName,
            content: content,
            nbWords: nbWords
        });

        this.setState({
            documentList: [...this.state.documentList, label]
        }, () => {
            this.updatePrices();
            resetForm({});
        });
    }

    removeDocument(title) {
        const name = title.split(" : ")[1];
        this.document = this.document.filter(doc => doc.title !== name);
        const newList = this.state.documentList.filter(doc => doc !== title);

        this.setState({
            documentList: newList
        }, this.updatePrices);
    }

    updatePrices() {

        let nbWord = 0;
        this.document.map((doc) => {
            nbWord += doc.nbWords;
        });

        const params = {
            currency: this.state.currency,
            language: utils.getLanguage().toUpperCase(),
            nbWord: nbWord
        }

        const options = {
            url: Constants.baseUrl + Constants.pricesRoute,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: params
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        prices: response.data.prices
                    });
                }
            })
            .catch(error => {
                this.setState({
                    prices: {}
                });
                console.log(error);
            });
    }

    updateLanguages = (langs) => {
        this.langSelected = langs;
    }

    clickSubmit = (valid) => {
        if (!valid) {
            this.setState({submitAlreadyClick: true});
        }
    }

    handleSubmit = (fields) => {
        console.log(fields);
        const step3data = {
            documentLangages: fields.lang,
            documents: this.document,
            currency: this.state.currency
        };
        console.log(step3data);

        this.updateFields(step3data);
        this.next();
    }

    clickAddDoc = (valid) => {
        if (!valid) {
            this.setState({documentSubmitAlreadyClick: true});
        }
    }

    render() {

        const {t} = this.props;

        return (
            <div>
                {this.renderAddDocument()}
                <Formik
                    validationSchema={this.schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize={true}
                    initialValues={{
                        lang: this.langSelected,
                        documentListSize: this.state.documentList.length
                    }}
                >
                    {({
                          handleSubmit,
                          values,
                          setFieldValue,
                          isValid,
                          errors,
                          handleChange
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm>
                                    <h3>{t("REGISTER_STEP3_DOC_LIST")}</h3>
                                </Col>
                            </Form.Row>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm>
                                    {
                                        this.state.documentList.length === 0 ? (
                                            <span>{t("REGISTER_STEP3_NO_DOC")}</span>
                                        ) : this.state.documentList.map((docName, i) => (
                                            <div key={i} onClick={() => this.removeDocument(docName)}>
                                                <FontAwesomeIcon icon={faTimes} color="#E12800" style={styles.icon}/>
                                                {docName}
                                            </div>
                                        ))
                                    }
                                    <Form.Control
                                        name="documentListSize"
                                        type="hidden"
                                        value={values.documentListSize}
                                        onChange={handleChange}
                                        isValid={this.state.submitAlreadyClick && !errors.documentListSize}
                                        isInvalid={this.state.submitAlreadyClick && errors.documentListSize}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{t("REGISTER_STEP3_INVALID_DOC_LIST")}</Form.Control.Feedback>
                                </Col>
                            </Form.Row>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm>
                                    <h3>{t("REGISTER_STEP3_SELECT_LANG")}</h3>
                                </Col>
                            </Form.Row>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm>
                                    <Form.Label>{t("REGISTER_STEP3_CURRENCY_LABEL")}</Form.Label>
                                    {Object.keys(this.currencySymbol).map((currency) => (
                                        <Form.Check
                                            key={currency}
                                            type="radio"
                                            label={t("REGISTER_STEP3_PAYMENT_CURRENCY_" + currency)}
                                            checked={this.state.currency === currency ? "checked" : ""}
                                            onChange={() => {
                                                this.setState({
                                                    currency: currency
                                                }, this.updatePrices);
                                            }}
                                        />
                                    ))}
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col sm style={styles.formWrapper}>
                                    <ListGroup>
                                        {this.languages.map((lang) => (
                                            <ListGroup.Item>
                                                <Form.Check
                                                    style={styles.inline}
                                                    key={lang}
                                                    type="checkbox"
                                                    label={t("REGISTER_STEP3_LABEL_LANG_" + lang)}
                                                    checked={values.lang.includes(lang) ? "checked" : ""}
                                                    onChange={() => {
                                                        if (values.lang.includes(lang)) {
                                                            const nextValue = values.lang.filter(
                                                                value => value !== lang
                                                            );
                                                            setFieldValue("lang", nextValue);
                                                            this.updateLanguages(nextValue);
                                                        } else {
                                                            values.lang.push(lang);
                                                            setFieldValue("lang", values.lang);
                                                            this.updateLanguages(values.lang);
                                                        }
                                                    }}
                                                    isValid={this.state.submitAlreadyClick && !errors.lang}
                                                    isInvalid={this.state.submitAlreadyClick && errors.lang}
                                                />
                                                <div style={styles.price} className="pull-right">
                                                    {

                                                        this.state.prices[lang] ?
                                                            this.state.prices[lang].toFixed(2) + " " + this.currencySymbol[this.state.currency] :
                                                            <span>
                                                                <FontAwesomeIcon icon={faQuestionCircle}
                                                                                 color={Colors.main}
                                                                                 style={styles.icon}/>
                                                                {" " + this.currencySymbol[this.state.currency]}
                                                            </span>
                                                    }
                                                </div>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                                <Col sm style={styles.formWrapper}/>
                            </Form.Row>
                            <Form.Row>
                                <Col sm style={styles.formWrapper}>
                                    <Button variant="primary" onClick={() => this.back()}>
                                        {t("REGISTER_STEP3_PREV")}
                                    </Button>
                                    <Button variant="primary" type="submit" className="float-right"
                                            onClick={() => this.clickSubmit(isValid)}>
                                        {t("REGISTER_STEP3_NEXT")}
                                    </Button>
                                </Col>
                            </Form.Row>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }

    renderAddDocument() {
        const {t} = this.props;

        return (
            <Formik
                validationSchema={this.docSchema}
                onSubmit={this.addDocument}
                initialValues={{}}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Row style={styles.formWrapper}>
                            <span>
                                <Badge pill variant="primary" style={styles.info}>
                                    i
                                </Badge>
                            </span>
                            <Col>
                                <span dangerouslySetInnerHTML={{__html: t("REGISTER_STEP3_INFO")}}/>
                            </Col>
                        </Form.Row>
                        <Form.Row style={styles.formWrapper}>
                            <Col sm style={styles.formWrapper}>
                                <h3>{t("REGISTER_STEP3_ADD_DOC")}</h3>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP3_LABEL_DOC_NAME")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="docName"
                                    value={values.docName || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP3_PLACEHOLDER_DOC_NAME")}
                                    isValid={this.state.documentSubmitAlreadyClick && !errors.docName}
                                    isInvalid={this.state.documentSubmitAlreadyClick && errors.docName}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP3_INVALID_DOC_NAME")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}/>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP3_LABEL_DOC_CONTENT")}</Form.Label>
                                <Form.Control
                                    name="docContent"
                                    as="textarea"
                                    rows="10"
                                    value={values.docContent || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP3_PLACEHOLDER_DOC_CONTENT")}
                                    isValid={this.state.documentSubmitAlreadyClick && !errors.docContent}
                                    isInvalid={this.state.documentSubmitAlreadyClick && errors.docContent}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP3_INVALID_DOC_CONTENT")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Button variant="primary" type="submit" className="float-right"
                                        onClick={() => this.clickAddDoc(isValid)}>
                                    {t("REGISTER_STEP3_DOC_ADD")}
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        );
    }
}


const styles = {
    formWrapper: {
        marginTop: "1rem",
    },
    info: {
        fontSize: "1rem",
        marginRight: "0.8rem"
    },
    info2: {
        fontSize: "1rem",
        width: "2rem"
    },
    icon: {
        marginRight: "0.3rem"
    },
    inline: {
        display: "inline-block"
    },
    price: {
        display: "inline-block",
        float: "right"
    }
};

export default withTranslation()(Step3)
