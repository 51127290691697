import * as React from "react";
import {Alert, Button, Col, Container, Form, Image, Row, Spinner} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import Constants from "../../constants";
import * as axios from "axios";

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);

        const currentPath = this.props.location.pathname;

        this.state = {
            alert: {
                type: "",
                txt: "",
            },
            token: currentPath.replace("/resetpassword/", ""),
            pass1: "",
            pass2: "",
            curentlySend: false
        }
    }

    onValueChange = (value, key) => {
        this.setState({
            [key]: value
        });
    }

    sumbit = () => {
        const {t} = this.props;

        if (this.state.pass1 === "" || this.state.pass2 === "") {
            this.setState({
                alert: {
                    type: "danger",
                    txt: t("RESET_PASSWORD_REQUIRED")
                },
                curentlySend: false
            });

            return;
        }

        if (this.state.pass1 !== this.state.pass2) {
            this.setState({
                alert: {
                    type: "danger",
                    txt: t("RESET_PASSWORD_NOT_MATCH")
                },
                curentlySend: false
            });

            return;
        }

        const options = {
            url: Constants.baseUrl + Constants.resetPasswordRoute + this.state.token,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
                newPassword: this.state.pass1
            }
        };

        this.setState({
            curentlySend: true
        });

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        alert: {
                            type: "success",
                            txt: t("RESET_PASSWORD_OK")
                        },
                        curentlySend: false,
                        pass1: "",
                        pass2: ""
                    });
                }
            })
            .catch(error => {
                let errorTxt = t("RESET_PASSWORD_ERROR");
                if (error.response.status === 400) {
                    errorTxt = t("RESET_PASSWORD_BAD_PATTERN");
                } else if (error.response.status === 404) {
                    errorTxt = t("RESET_PASSWORD_BAD_TOKEN");
                }
                
                this.setState({
                    alert: {
                        type: "danger",
                        txt: errorTxt
                    },
                    curentlySend: false
                });
            });
    }

    closeAlert = () => {
        this.setState({
            alert: {
                type: "",
                txt: "",
            }
        })
    }

    render() {

        const {t} = this.props;

        return (
            <Container>
                <Row className="justify-content-center">
                    <Col xs sm="5" style={styles.logo}>
                        <Image src="/logo_extend.jpg" fluid/>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {
                        this.state.alert.type !== "" ? (
                            <Alert variant={this.state.alert.type} onClose={this.closeAlert} dismissible>
                                {this.state.alert.txt}
                            </Alert>
                        ) : null
                    }
                </Row>
                <Row className="justify-content-center">
                    <Col sm style={styles.formWrapper}>
                        <Form.Label>{t("RESET_PASSWORD_PASS_LABEL")}</Form.Label>
                        <Form.Control
                            type="password"
                            name="password1"
                            value={this.state.pass1}
                            onChange={(event) => this.onValueChange(event.target.value, 'pass1')}
                            placeholder={t("RESET_PASSWORD_PASS_PLACEHOLDER")}
                        />
                    </Col>
                    <Col sm style={styles.formWrapper}>
                        <Form.Label>{t("RESET_PASSWORD_PASS_CONFIRM_LABEL")}</Form.Label>
                        <Form.Control
                            type="password"
                            name="password2"
                            value={this.state.pass2}
                            onChange={(event) => this.onValueChange(event.target.value, 'pass2')}
                            placeholder={t("RESET_PASSWORD_PASS_PLACEHOLDER")}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center" style={styles.submitWrapper}>
                    <Col sm style={styles.formWrapper}>
                        <Button variant="primary" type="submit" className="float-right"
                                onClick={this.sumbit}
                                disabled={this.state.curentlySend ? "disabled" : null}>
                            {
                                this.state.curentlySend === true ? (
                                    <Spinner animation="grow" size="sm" variant="light"
                                             style={styles.spinner}/>
                                ) : null
                            }
                            {t("RESET_PASSWORD_SUBMIT")}
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const styles = {
    logo: {
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    formWrapper: {
        marginTop: "1rem",
    },
    spinner: {
        marginRight: "1rem",
    },
    hidden: {
        display: "none",
    },
    submitWrapper: {
        marginTop: "1rem",
        marginBottom: "2rem",
    }
};

export default withTranslation()(ResetPassword)
