import * as React from "react";
import {withTranslation} from "react-i18next";
import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import {Button, Spinner} from "react-bootstrap";
import * as axios from "axios";
import Constant from "../../../constants";
import {useState} from "react";

const CardForm = ({t, getFieldValue, paymentSuccessful, setPaymentError}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setPaymentError(false);
        setSubmitting(true);

        try {
            const response = await createPaymentIntent();

            const payload = await stripe.confirmCardPayment(response.clientSecret, {
                payment_method: {
                    card: elements.getElement(CardNumberElement),
                    billing_details: {
                        name: document.getElementById("payment-name-input").value,
                        address: {
                            line1: getFieldValue("billingAddressStreet"),
                            city: getFieldValue("billingAddressCity"),
                            postal_code: getFieldValue("billingAddressZipCode"),
                            country: getFieldValue("billingAddressCountry"),
                        }
                    },
                }
            });

            setSubmitting(false);

            if (payload.paymentIntent) {
                if (payload.paymentIntent.status === "succeeded") {
                    paymentSuccessful();
                } else {
                    setPaymentError(true);
                }
            } else {
                setPaymentError(true);
            }

            console.log("[PaymentMethod]", payload);
        } catch (e) {
            setSubmitting(false);
            setPaymentError(true);
            console.error(e);
        }
    };

    const createPaymentIntent = async () => {

        const options = {
            url: Constant.baseUrl + Constant.createPaymentIntentRoute + "CB/" + getFieldValue('ref') + "/" + getFieldValue("currency"),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };

        const response = await axios(options);

        return response.data;
    }


    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12" style={styles.inputWrapper}>
                    <input type="text" placeholder={t("REGISTER_STEP5_PLACEHOLDER_CARD_NAME")} id="payment-name-input"
                           style={styles.inputName}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={styles.inputWrapper}>
                    <CardNumberElement
                        options={{
                            style: styles.stripeStyle,
                            placeholder: t("REGISTER_STEP5_PLACEHOLDER_CARD_NUMBER")
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6" style={styles.inputWrapper}>
                    <CardExpiryElement
                        options={{
                            style: styles.stripeStyle,
                            placeholder: t("REGISTER_STEP5_PLACEHOLDER_CARD_EXPIRE")
                        }}
                    />
                </div>
                <div className="col-6" style={styles.inputWrapper}>
                    <CardCvcElement
                        options={{
                            style: styles.stripeStyle,
                            placeholder: t("REGISTER_STEP5_PLACEHOLDER_CARD_CVC")
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={!stripe || submitting}
                        style={styles.submitButton}>
                        {
                            submitting ? (
                                <Spinner
                                    animation="grow"
                                    size="sm"
                                    variant="light"
                                    style={styles.spinner}/>
                            ) : null
                        }
                        {t("REGISTER_STEP5_PAY")}
                    </Button>
                </div>
            </div>
        </form>
    );
};

const styles = {
    inputWrapper: {
        border: "1px solid #ced4da",
        padding: "4px",
        marginTop: "0.5rem"
    },
    stripeStyle: {
        base: {
            fontWeight: "normal",
            fontSize: '18px',
            color: '#495057',
            '::placeholder': {
                color: '#aab7c4',
            },
            backgroundColor: '#fff',
        },
        invalid: {
            color: '#ff0d0d',
        },
    },
    inputName: {
        fontSize: '18px',
        color: '#495057',
        outline: 'none',
        border: "none",
        width: "100%",
        padding: 0
    },
    submitButton: {
        width: "100%",
        marginTop: "1rem"
    },
    spinner: {
        marginRight: "0.5rem"
    }
}

export default withTranslation()(CardForm)
