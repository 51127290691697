import * as React from "react";
import {withTranslation} from "react-i18next";
import {Col, Row} from "react-bootstrap";

class Footer extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        const {t} = this.props;

        return (
            <Row>
                <Col>
                    © {new Date().getFullYear()} - WOHI
                    <span style={styles.separator}>|</span>
                    <a href="/legal">{t("FOOTER_LEGAL_NOTICE")}</a>
                    <span style={styles.separator}>|</span>
                    <a href="/terms">{t("FOOTER_TERMS_CONDITIONS")}</a>
                    <span style={styles.separator}>|</span>
                    <a href="/privacyPolicy">{t("FOOTER_PP")}</a>
                </Col>
            </Row>
        )

    }

}

const styles = {
    separator: {
        marginLeft: "1rem",
        marginRight: "1rem",
    }
}

export default withTranslation()(Footer)
