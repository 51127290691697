import * as React from "react";
import {Alert, Button, Col, Container, Form, Image, Row, Spinner} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import Constants from "../../constants";
import * as axios from "axios";
import {load} from 'recaptcha-v3'
import {Formik} from "formik";
import * as yup from 'yup';
import "../../utils/yup-phone";
import Scroll from "react-scroll";

class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.schema = yup.object({
            lastName: yup.string().min(2).required(),
            firstName: yup.string().min(2).required(),
            email: yup.string().email().required(),
            phone: yup.string().phone().required(),
            location: yup.string().min(2).required(),
            subject: yup.string().min(2).required(),
            message: yup.string().min(10).required(),
        });

        this.state = {
            curentlySend: false,
            submitAlreadyClick: false,
            alert: {
                type: "",
                txt: "",
            },
        }
    }

    scrollToFormTop() {
        Scroll.scroller.scrollTo('alert', {
            duration: 1000,
            smooth: true,
            offset: -15
        })
    }

    handleSubmit = (fields, {setSubmitting, setErrors, setStatus, resetForm}) => {
        this.setState({
            curentlySend: true
        }, () => this.sendForm(fields, resetForm));
    }

    clickSubmit = (valid) => {
        if (!valid) {
            this.setState({submitAlreadyClick: true}, this.scrollToFormTop);
        }
    }

    async getReCaptchaToken() {
        const recaptcha = await load(Constants.recaptchakey, {explicitRenderParameters: {badge: "bottomleft"}})
        return await recaptcha.execute('contact')
    }

    async sendForm(values, resetForm) {
        const {t} = this.props;

        values["recaptcha"] = await this.getReCaptchaToken();

        const options = {
            url: Constants.baseUrl + Constants.contactRoute,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: values
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        alert: {
                            type: "success",
                            txt: t("CONTACT_FORM_OK")
                        },
                        curentlySend: false,
                        submitAlreadyClick: false,
                    }, () => resetForm({}));
                }
            })
            .catch(error => {
                const body = error.response;

                if (body === undefined) {
                    this.setState({
                        alert: {
                            type: "danger",
                            txt: t("CONTACT_FORM_UNKNOWN_ERROR")
                        },
                        curentlySend: false
                    });
                } else {
                    this.setState({
                        alert: {
                            type: "danger",
                            txt: t("CONTACT_FORM_BACK_ERROR")
                        },
                        curentlySend: false
                    });
                }
            })
            .then(() => {
                this.scrollToFormTop();
            });
    }

    closeAlert = () => {
        this.setState({
            alert: {
                type: "",
                txt: "",
            }
        })
    }

    styleForm() {
        if (this.state.alert.type === "success") {
            return styles.hidden;
        }
    }

    render() {

        const {t} = this.props;

        return (
            <Container>
                <Row className="justify-content-center">
                    <Col xs sm="5" style={styles.logo}>
                        <Image src="/logo_extend.jpg" fluid/>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col sm="auto" style={styles.logo}>
                        <h1>{t("CONTACT_FORM_TITLE")}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <Scroll.Element name="alert">
                            {
                                this.state.alert.type !== "" ? (
                                    <Alert variant={this.state.alert.type} onClose={this.closeAlert} dismissible>
                                        {this.state.alert.txt}
                                    </Alert>
                                ) : null
                            }
                        </Scroll.Element>
                        <Formik
                            validationSchema={this.schema}
                            onSubmit={this.handleSubmit}
                            initialValues={{}}
                        >
                            {({
                                  handleSubmit,
                                  handleChange,
                                  handleBlur,
                                  values,
                                  touched,
                                  isValid,
                                  errors,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit} style={this.styleForm()}>
                                    <Form.Row>
                                        <Col sm style={styles.formWrapper}>
                                            <Form.Label>{t("CONTACT_FORM_LABEL_LASTNAME")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="lastName"
                                                value={values.lastName || ''}
                                                onChange={handleChange}
                                                placeholder={t("CONTACT_FORM_PLACEHOLDER_LASTNAME")}
                                                isValid={this.state.submitAlreadyClick && !errors.lastName}
                                                isInvalid={this.state.submitAlreadyClick && errors.lastName}
                                                disabled={this.state.curentlySend ? "disabled" : null}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{t("CONTACT_FORM_INVALID_LASTNAME")}</Form.Control.Feedback>
                                        </Col>
                                        <Col sm style={styles.formWrapper}>
                                            <Form.Label>{t("CONTACT_FORM_LABEL_FIRSTNAME")}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="firstName"
                                                value={values.firstName || ''}
                                                onChange={handleChange}
                                                placeholder={t("CONTACT_FORM_PLACEHOLDER_FIRSTNAME")}
                                                isValid={this.state.submitAlreadyClick && !errors.firstName}
                                                isInvalid={this.state.submitAlreadyClick && errors.firstName}
                                                disabled={this.state.curentlySend ? "disabled" : null}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{t("CONTACT_FORM_INVALID_FIRSTNAME")}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col sm style={styles.formWrapper}>
                                            <Form.Label>{t("CONTACT_FORM_LABEL_EMAIL")}</Form.Label>
                                            <Form.Control
                                                name="email"
                                                type="email"
                                                value={values.email || ''}
                                                onChange={handleChange}
                                                placeholder={t("CONTACT_FORM_PLACEHOLDER_EMAIL")}
                                                isValid={this.state.submitAlreadyClick && !errors.email}
                                                isInvalid={this.state.submitAlreadyClick && errors.email}
                                                disabled={this.state.curentlySend ? "disabled" : null}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{t("CONTACT_FORM_INVALID_EMAIL")}</Form.Control.Feedback>
                                        </Col>
                                        <Col sm style={styles.formWrapper}>
                                            <Form.Label>{t("CONTACT_FORM_LABEL_TEL")}</Form.Label>
                                            <Form.Control
                                                name="phone"
                                                type="tel"
                                                value={values.phone || ''}
                                                onChange={handleChange}
                                                placeholder={t("CONTACT_FORM_PLACEHOLDER_TEL")}
                                                isValid={this.state.submitAlreadyClick && !errors.phone}
                                                isInvalid={this.state.submitAlreadyClick && errors.phone}
                                                disabled={this.state.curentlySend ? "disabled" : null}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{t("CONTACT_FORM_INVALID_TEL")}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col sm style={styles.formWrapper}>
                                            <Form.Label>{t("CONTACT_FORM_LABEL_LOCATION")}</Form.Label>
                                            <Form.Control
                                                name="location"
                                                type="text"
                                                value={values.location || ''}
                                                onChange={handleChange}
                                                placeholder={t("CONTACT_FORM_PLACEHOLDER_LOCATION")}
                                                isValid={this.state.submitAlreadyClick && !errors.location}
                                                isInvalid={this.state.submitAlreadyClick && errors.location}
                                                disabled={this.state.curentlySend ? "disabled" : null}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{t("CONTACT_FORM_INVALID_LOCATION")}</Form.Control.Feedback>
                                        </Col>
                                        <Col sm style={styles.formWrapper}/>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col sm style={styles.formWrapper}>
                                            <Form.Label>{t("CONTACT_FORM_LABEL_SUBJECT")}</Form.Label>
                                            <Form.Control
                                                name="subject"
                                                type="text"
                                                value={values.subject || ''}
                                                onChange={handleChange}
                                                placeholder={t("CONTACT_FORM_PLACEHOLDER_SUBJECT")}
                                                isValid={this.state.submitAlreadyClick && !errors.subject}
                                                isInvalid={this.state.submitAlreadyClick && errors.subject}
                                                disabled={this.state.curentlySend ? "disabled" : null}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{t("CONTACT_FORM_INVALID_SUBJECT")}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col sm style={styles.formWrapper}>
                                            <Form.Label>{t("CONTACT_FORM_LABEL_MSG")}</Form.Label>
                                            <Form.Control
                                                name="message"
                                                as="textarea"
                                                rows="5"
                                                value={values.message || ''}
                                                onChange={handleChange}
                                                placeholder={t("CONTACT_FORM_PLACEHOLDER_MSG")}
                                                isValid={this.state.submitAlreadyClick && !errors.message}
                                                isInvalid={this.state.submitAlreadyClick && errors.message}
                                                disabled={this.state.curentlySend ? "disabled" : null}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{t("CONTACT_FORM_INVALID_MSG")}</Form.Control.Feedback>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col sm style={styles.formWrapper}>
                                            <Button variant="primary" type="submit" className="float-right"
                                                    onClick={() => this.clickSubmit(isValid)}
                                                    disabled={this.state.curentlySend ? "disabled" : null}>
                                                {
                                                    this.state.curentlySend === true ? (
                                                        <Spinner animation="grow" size="sm" variant="light"
                                                                 style={styles.spinner}/>
                                                    ) : null
                                                }
                                                {t("CONTACT_FORM_SEND")}
                                            </Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const styles = {
    form: {
        marginBottom: "5em",
    },
    logo: {
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    formWrapper: {
        marginTop: "1rem",
    },
    spinner: {
        marginRight: "1rem",
    },
    hidden: {
        display: "none",
    }
};

export default withTranslation()(Contact)
