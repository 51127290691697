import * as React from "react";
import {withTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import { CsvToHtmlTable } from 'react-csv-to-table';


class Pricing extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;


        return (
          <><h1>{t("PRICING_fr")}</h1><Table responsive>
            <thead>
              <tr>
                <th>{t("PRICING_lang")}</th>
                <th>0 - 1 500</th>
                <th>1 501 - 3 000</th>
                <th>3 001 - 4 500</th>
                <th>4 501 - 6 000</th>
                <th>6 001 - 7 500</th>
                <th>7 501 - 9 000</th>
                <th>9 001 - 10 500</th>
                <th>10 501 - 13 000</th>
                <th>13 001 - 15 500</th>
                <th>15 501 - 17 000</th>
                <th>117 001 - 20 000</th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td>{t("PRICING_fran")}</td><td>862,5</td><td>1712,5</td><td>2530</td><td>3042,5</td><td>3615</td><td>4187,5</td><td>4760</td><td>5300</td><td>5850</td><td>6550</td><td>7325</td>              </tr>
              <tr>
              <td>{t("PRICING_anglais")}</td><td>1425</td><td>2837,5</td><td>4217,5</td><td>5292,5</td><td>6427,5</td><td>7562,5</td><td>8697,5</td><td>10175</td><td>11662,5</td><td>12950</td><td>15075</td>              </tr>
              <tr>
              <td>{t("PRICING_allem")}</td><td>1550</td><td>3087,5</td><td>4592,5</td><td>5542,5</td><td>6802,5</td><td>8437,5</td><td>9697,5</td><td>10862,5</td><td>12412,5</td><td>13800</td><td>16075</td>              </tr>
              <tr>
              <td>{t("PRICING_Espa")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td>              </tr>
              <tr>
              <td>{t("PRICING_ital")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td>              </tr>
              <tr>
              <td>{t("PRICING_neerl")}</td><td>1550</td><td>3087,5</td><td>4592,5</td><td>5542,5</td><td>6802,5</td><td>8437,5</td><td>9697,5</td><td>10862,5</td><td>12412,5</td><td>13800</td><td>16075</td>              </tr>
            </tbody>
          </Table>
          
          <h1>{t("PRICING_al")}</h1><Table responsive>
            <thead>
              <tr>
                <th>{t("PRICING_lang")}</th>
                <th>0 - 1 500</th>
                <th>1 501 - 3 000</th>
                <th>3 001 - 4 500</th>
                <th>4 501 - 6 000</th>
                <th>6 001 - 7 500</th>
                <th>7 501 - 9 000</th>
                <th>9 001 - 10 500</th>
                <th>10 501 - 13 000</th>
                <th>13 001 - 15 500</th>
                <th>15 501 - 17 000</th>
                <th>117 001 - 20 000</th>
              </tr>
            </thead>
            <tbody>
            <tr><td>{t("PRICING_allem")}</td><td>862,5</td><td>1712,5</td><td>2530</td><td>3042,5</td><td>3615</td><td>4187,5</td><td>4760</td><td>5300</td><td>5850</td><td>6550</td><td>7325</td></tr><tr><td>{t("PRICING_anglais")}</td><td>1425</td><td>2837,5</td><td>4217,5</td><td>5292,5</td><td>6427,5</td><td>7562,5</td><td>8697,5</td><td>10175</td><td>11662,5</td><td>12950</td><td>15075</td></tr><tr><td>{t("PRICING_fran")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td></tr><tr><td>{t("PRICING_Espa")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td></tr><tr><td>{t("PRICING_ital")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td></tr><tr><td>{t("PRICING_neerl")}</td><td>1550</td><td>3087,5</td><td>4592,5</td><td>5542,5</td><td>6802,5</td><td>8437,5</td><td>9697,5</td><td>10862,5</td><td>12412,5</td><td>13800</td><td>16075</td></tr>
            </tbody>
          </Table>

          <h1>{t("PRICING_ang")}</h1><Table responsive>
            <thead>
              <tr>
                <th>{t("PRICING_lang")}</th>
                <th>0 - 1 500</th>
                <th>1 501 - 3 000</th>
                <th>3 001 - 4 500</th>
                <th>4 501 - 6 000</th>
                <th>6 001 - 7 500</th>
                <th>7 501 - 9 000</th>
                <th>9 001 - 10 500</th>
                <th>10 501 - 13 000</th>
                <th>13 001 - 15 500</th>
                <th>15 501 - 17 000</th>
                <th>117 001 - 20 000</th>
              </tr>
            </thead>
            <tbody>
            <tr><td>{t("PRICING_anglais")}</td><td>862,5</td><td>1712,5</td><td>2530</td><td>3042,5</td><td>3615</td><td>4187,5</td><td>4760</td><td>5300</td><td>5850</td><td>6550</td><td>7325</td></tr><tr><td>{t("PRICING_fran")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td></tr><tr><td>{t("PRICING_allem")}</td><td>1550</td><td>3087,5</td><td>4592,5</td><td>5542,5</td><td>6802,5</td><td>8437,5</td><td>9697,5</td><td>10862,5</td><td>12412,5</td><td>13800</td><td>16075</td></tr><tr><td>{t("PRICING_Espa")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td></tr><tr><td>{t("PRICING_ital")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td></tr><tr><td>{t("PRICING_neerl")}</td><td>1550</td><td>3087,5</td><td>4592,5</td><td>5542,5</td><td>6802,5</td><td>8437,5</td><td>9697,5</td><td>10862,5</td><td>12412,5</td><td>13800</td><td>16075</td></tr>            </tbody>
          </Table>

          <h1>{t("PRICING_es")}</h1><Table responsive>
            <thead>
              <tr>
                <th>{t("PRICING_lang")}</th>
                <th>0 - 1 500</th>
                <th>1 501 - 3 000</th>
                <th>3 001 - 4 500</th>
                <th>4 501 - 6 000</th>
                <th>6 001 - 7 500</th>
                <th>7 501 - 9 000</th>
                <th>9 001 - 10 500</th>
                <th>10 501 - 13 000</th>
                <th>13 001 - 15 500</th>
                <th>15 501 - 17 000</th>
                <th>117 001 - 20 000</th>
              </tr>
            </thead>
            <tbody>
            <tr><td>{t("PRICING_Espa")}</td><td>862,5</td><td>1712,5</td><td>2530</td><td>3042,5</td><td>3615</td><td>4187,5</td><td>4760</td><td>5300</td><td>5850</td><td>6550</td><td>7325</td></tr><tr><td>{t("PRICING_anglais")}</td><td>1425</td><td>2837,5</td><td>4217,5</td><td>5292,5</td><td>6427,5</td><td>7562,5</td><td>8697,5</td><td>10175</td><td>11662,5</td><td>12950</td><td>15075</td></tr><tr><td>{t("PRICING_allem")}</td><td>1550</td><td>3087,5</td><td>4592,5</td><td>5542,5</td><td>6802,5</td><td>8437,5</td><td>9697,5</td><td>10862,5</td><td>12412,5</td><td>13800</td><td>16075</td></tr><tr><td>{t("PRICING_fran")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td></tr><tr><td>{t("PRICING_ital")}</td><td>1500</td><td>2987,5</td><td>4442,5</td><td>5417,5</td><td>6677,5</td><td>8187,5</td><td>9417,5</td><td>10587,5</td><td>12112,5</td><td>13460</td><td>15675</td></tr><tr><td>{t("PRICING_neerl")}</td><td>1550</td><td>3087,5</td><td>4592,5</td><td>5542,5</td><td>6802,5</td><td>8437,5</td><td>9697,5</td><td>10862,5</td><td>12412,5</td><td>13800</td><td>16075</td></tr>      
                </tbody>
                </Table>

          </>


        )

    }
}

export default withTranslation()(Pricing)
