import * as React from "react";
import {withTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";

class Terms extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <Container>
                <Row>
                    <Col>
                        <h1>{t("CGV_TITLE")}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_UPDATE_TIME")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TDEF")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_DEF")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE1")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT1")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE2")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT2")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE3")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT3")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE4")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT4")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE5")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT5")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE6")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT6")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE7")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT7")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE8")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT8")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE9")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT9")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("CGV_TITLE10")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("CGV_TXT10")}}/>
                    </Col>
                </Row>
            </Container>

        )

    }
}

export default withTranslation()(Terms)
