import * as React from "react";
import {withTranslation} from "react-i18next";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faApple, faGooglePlay} from '@fortawesome/free-brands-svg-icons';
import screen1 from "../../images/home/screen1.png";
import screen2 from "../../images/home/screen2.png";
import screen3 from "../../images/home/screen3.png";
import constants from "../../constants";

class Home extends React.Component {

    openIOS = () => {
        window.open(constants.iosStoreUrl, "_blank");
    }

    openAndroid = () => {
        window.open(constants.androidStoreUrl, "_blank");
    }

    render() {

        const {t} = this.props;

        return (
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} style={styles.logo}>
                        <Image src="/logo_extend.jpg" fluid/>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={10} style={styles.title}>
                        <h2>{t("HOME_TITLE")}</h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={12} style={styles.text}>
                        <p dangerouslySetInnerHTML={{__html: t("HOME_TEXT")}}/>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={3} style={styles.text}>
                        <Button variant="primary" size="lg" style={styles.button} onClick={this.openIOS}>
                            <FontAwesomeIcon icon={faApple}
                                             color={"white"}
                                             style={styles.buttonIcon}/>
                            {t("HOME_DOWNLOAD")}
                        </Button>
                    </Col>
                    <Col md={3} style={styles.text}>
                        <Button variant="primary" size="lg" style={styles.button} onClick={this.openAndroid}>
                            <FontAwesomeIcon icon={faGooglePlay}
                                             color={"white"}
                                             style={styles.buttonIcon}/>
                            {t("HOME_DOWNLOAD")}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={4} className="text-center">
                        <Image src={screen1} style={styles.screenshot}/>
                        <h2>{t("HOME_SCREEN_1_TITLE")}</h2>
                        <p dangerouslySetInnerHTML={{__html: t("HOME_SCREEN_1_DESC")}}/>
                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <Image src={screen2} style={styles.screenshot}/>
                        <h2>{t("HOME_SCREEN_2_TITLE")}</h2>
                        <p dangerouslySetInnerHTML={{__html: t("HOME_SCREEN_2_DESC")}}/>
                    </Col>
                    <Col xs={12} md={4} className="text-center">
                        <Image src={screen3} style={styles.screenshot}/>
                        <h2>{t("HOME_SCREEN_3_TITLE")}</h2>
                        <p dangerouslySetInnerHTML={{__html: t("HOME_SCREEN_3_DESC")}}/>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const styles = {
    logo: {
        marginTop: "2rem",
        marginBottom: "3rem"
    },
    title: {
        textAlign: "center",
        marginBottom: "2rem"
    },
    text: {},
    button: {
        width: "100%",
        marginTop: "1rem"
    },
    buttonIcon: {
        marginRight: "1rem"
    },
    screenshot: {
        width: "100%",
        marginTop: "1rem",
        marginBottom: "1rem",
        padding: "1rem",
    }
}

export default withTranslation()(Home)
