import * as React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {BrowserRouter as Router, Link, NavLink, Route, Switch} from "react-router-dom";
import Home from "../home";
import Register from "../register";
import Contact from "../contact";
import Pricing from "../pricing"
import {withTranslation} from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";
import i18next from "i18next";
import * as utils from "../../utils/language";
import Footer from "../footer";
import LegalNotice from "../legalNotice";
import PrivacyPolicy from "../privacyPolicy";
import Terms from "../terms";
import ResetPassword from "../resetPassword";
import Bill from "../bill";

class Main extends React.Component {


    getFullLanguage = () => {
        const {t} = this.props;
        const lang = utils.getLanguage();
        let fullLang = "";

        switch (lang.substr(0, 2)) {
            case "en":
                fullLang = t("MENU_ENGLISH");
                break;
            case "fr":
                fullLang = t("MENU_FRENCH");
                break;
            case "de":
                fullLang = t("MENU_GERMAN");
                break;
            case "es":
                fullLang = t("MENU_SPANISH");
                break;
        }

        return fullLang;
    };

    changeLanguage = (lang) => {
        i18next.changeLanguage(lang);
    };

    render() {

        const {t} = this.props;

        return (
            <Router>
                <div className="App">
                    <Navbar bg="light" expand="lg">
                        <Navbar.Brand href="/">
                            <img
                                alt="Logo"
                                src="/logo.jpg"
                                width="30"
                                height="30"
                                className="d-inline-block align-top"
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                        <Navbar.Collapse>
                            <Nav className="mr-auto">
                                <Nav.Link href="/">{t("MENU_HOME")}</Nav.Link>
                                <Nav.Link href="/register">{t("MENU_REGISTER")}</Nav.Link>
                                <Nav.Link href="/contact">{t("MENU_CONTACT")}</Nav.Link>
                                <Nav.Link href="/pricing">{t("MENU_PRICING")}</Nav.Link>
                            </Nav>
                            <Nav className="justify-content-end" onSelect={this.changeLanguage}>
                                <NavDropdown alignRight title={this.getFullLanguage()}>
                                    <NavDropdown.Item eventKey="en">
                                        <img
                                            style={styles.flagImg}
                                            alt="Flag"
                                            src="/flag/en.png"
                                        />
                                        {t("MENU_ENGLISH")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="fr">
                                        <img
                                            style={styles.flagImg}
                                            alt="Flag"
                                            src="/flag/fr.png"
                                        />
                                        {t("MENU_FRENCH")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="de">
                                        <img
                                            style={styles.flagImg}
                                            alt="Flag"
                                            src="/flag/de.png"
                                        />
                                        {t("MENU_GERMAN")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item eventKey="es">
                                        <img
                                            style={styles.flagImg}
                                            alt="Flag"
                                            src="/flag/es.png"
                                        />
                                        {t("MENU_SPANISH")}
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>

                        </Navbar.Collapse>
                    </Navbar>
                    <Switch>
                        <Route path="/home">
                            <Home/>
                        </Route>
                        <Route path="/register">
                            <Register/>
                        </Route>
                        <Route path="/contact">
                            <Contact/>
                        </Route>
                        <Route path="/legal">
                            <LegalNotice/>
                        </Route>
                        <Route path="/pricing">
                            <Pricing/>
                        </Route>
                        <Route path="/terms">
                            <Terms/>
                        </Route>
                        <Route path="/privacyPolicy">
                            <PrivacyPolicy/>
                        </Route>
                        <Route path="/resetpassword" component={ResetPassword}/>
                        <Route path="/bill" component={Bill}/>
                        <Route path="/">
                            <Home/>
                        </Route>
                    </Switch>
                </div>
                <footer>
                    <div style={styles.footer}>
                        <Footer/>
                    </div>
                </footer>
            </Router>
        );
    }
}

const styles = {
    flagImg: {
        height: 25,
        marginRight: 10
    },
    footer: {
        marginTop: "2rem",
        backgroundColor: "#F8F8F8",
        borderTop: "1px solid #E7E7E7",
        textAlign: "center",
        padding: "1rem",
        height: "4rem",
        width: "100%",
    }
};

export default withTranslation()(Main)
