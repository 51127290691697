import * as React from "react";
import {withTranslation} from "react-i18next";
import {Alert, Badge, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from "@stripe/stripe-js/pure";
import PaymentForm from "../paymentForm";
import Constants from "../../../constants";
import * as axios from "axios";
import Constant from "../../../constants";

class Step5 extends React.Component {

    constructor(props) {
        super(props);

        this.prev = this.props.prev;
        this.getFieldValue = this.props.getFieldValue;
        this.callbackReload = this.props.callbackReload;
        this.registerFinish = this.props.registerFinish;
        this.updateFields = this.props.updateFields;

        this.currencies = ["EUR", "USD"];
        this.paymentMethod = ["CB", "CHECK"];

        this.state = {
            submitAlreadyClick: false,
            radioCurrency: this.getFieldValue("currency"),
            radioMethod: "",
            onQuotation: false,
            price: null,
            loading: true,
            error: "",
            paymentError: false
        }

        this.currencySymbol = Constants.currencySymbol;
        this.stripePromise = loadStripe(Constants.stripePublicKey);
    }

    back = () => {
        this.prev();
    }

    componentWillReceiveProps({reload}) {
        if (reload) {
            this.setState({
                radioCurrency: this.getFieldValue("currency"),
                onQuotation: false,
                price: null,
                error: "",
                loading: true
            }, this.loadPrice);
            this.callbackReload();
        }
    }

    setPaymentError = (val) => {
        this.setState({
            paymentError: val
        })
    }

    updateCurrency = (currency) => {
        this.updateFields({
            currency: currency
        });
        this.setState({
            radioCurrency: currency,
            onQuotation: false,
            price: null,
            error: "",
            loading: true
        }, this.loadPrice);
    }

    loadPrice() {

        const {t} = this.props;
        const ref = this.getFieldValue("ref");

        const options = {
            url: Constants.baseUrl + Constants.getTotalPriceRoute + ref + "/" + this.state.radioCurrency,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    const data = response.data;
                    this.setState({
                        onQuotation: data.onQuotation,
                        price: data.price,
                        loading: false
                    })
                    console.log(response.data);
                }
            })
            .catch(error => {
                this.setState({
                    loading: false,
                    error: t("REGISTER_STEP5_ERROR_DETAIL").replace("%ref%", this.getFieldValue("ref"))
                })
            });
    }

    submitCheckPayment = () => {

        const options = {
            url: Constant.baseUrl + Constant.createPaymentIntentRoute + "CHECK/" + this.getFieldValue('ref') + "/" + this.getFieldValue("currency"),
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };

        axios(options)
            .then(response => {
                if (response.status === 201) {
                    this.registerFinish("CHECK");
                }
            })
            .catch(error => {
                //TODO erreur
                console.error(error);
            });
    }

    render() {

        const {t} = this.props;

        return (
            <div>
                <Form.Row style={styles.formWrapper}>
                    <Col sm style={styles.center}>
                        <h3>{t("REGISTER_STEP5_TITLE")}</h3>
                    </Col>
                </Form.Row>
                <Form.Row style={styles.formWrapper}>
                    <Col sm>
                        <Form.Label>{t("REGISTER_STEP5_PAYMENT_LABEL")}</Form.Label>
                        {this.currencies.map((currency) => (
                            <Form.Check
                                key={currency}
                                type="radio"
                                label={t("REGISTER_STEP5_PAYMENT_CURRENCY_" + currency)}
                                checked={this.state.radioCurrency === currency ? "checked" : ""}
                                onChange={() => this.updateCurrency(currency)}
                            />
                        ))}
                    </Col>
                </Form.Row>
                {
                    this.state.error !== "" ? (
                            <Form.Row style={styles.formWrapper}>
                                <Col sm style={styles.center}>
                                    <Alert variant="danger">
                                        <h1><Badge variant="primary">{t("REGISTER_STEP5_ERROR")}</Badge></h1>
                                        {this.state.error}
                                    </Alert>
                                </Col>
                            </Form.Row>
                        ) :
                        this.state.loading ? (
                                <Form.Row style={styles.formWrapper}>
                                    <Col sm style={styles.center}>
                                        <Spinner animation="grow" size="lg" variant="primary"/>
                                    </Col>
                                </Form.Row>
                            ) :
                            this.state.onQuotation ? (
                                <Form.Row style={styles.formWrapper}>
                                    <Col sm style={styles.center}>
                                        <Alert variant="secondary">
                                            <h1><Badge variant="primary">{t("REGISTER_STEP5_ON_QUOTATION")}</Badge>
                                            </h1>
                                            {t("REGISTER_STEP5_ON_QUOTATION_DETAIL").replace("%ref%", this.getFieldValue("ref"))}
                                        </Alert>
                                    </Col>
                                </Form.Row>
                            ) : (
                                <div>
                                    <Form.Row style={styles.formWrapper}>
                                        <Col sm style={styles.center}>
                                            <h3>
                                                {t("REGISTER_STEP5_PAYMENT_SUM")}
                                                {" " + this.state.price.toFixed(2)}
                                                {this.currencySymbol[this.state.radioCurrency]}
                                            </h3>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row style={styles.formWrapper}>
                                        <Col sm>
                                            <Form.Label>{t("REGISTER_STEP5_PAYMENT_LABEL")}</Form.Label>
                                            {this.paymentMethod.map((method) => (
                                                <Form.Check
                                                    key={method}
                                                    type="radio"
                                                    label={t("REGISTER_STEP5_METHOD_" + method)}
                                                    checked={this.state.radioMethod === method ? "checked" : ""}
                                                    onChange={() => {
                                                        this.setState({
                                                            radioMethod: method
                                                        });
                                                    }}
                                                />
                                            ))}
                                        </Col>
                                    </Form.Row>
                                    {this.renderPayment()}
                                </div>
                            )

                }

                <Row style={styles.backButton}>
                    <Col sm style={styles.formWrapper}>
                        <Button variant="primary" onClick={() => this.back()}>
                            {t("REGISTER_STEP5_PREV")}
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    renderPayment() {

        const {t} = this.props;

        switch (this.state.radioMethod) {
            case "CB":
                return (
                    <div>
                        <Form.Row className="align-items-center" style={styles.formWrapper}>
                            {
                                this.state.paymentError ? (
                                    <Col sm style={styles.center}>
                                        <Alert variant="danger">
                                            {t("PAYMENT_ERROR")}
                                        </Alert>
                                    </Col>
                                ) : null
                            }
                        </Form.Row>
                        <Form.Row className="align-items-center" style={styles.formWrapper}>
                            <Col sm={4}/>
                            <Col sm={4}>
                                <Elements stripe={this.stripePromise}>
                                    <PaymentForm
                                        stripe={this.stripePromise}
                                        getFieldValue={this.getFieldValue}
                                        setPaymentError={this.setPaymentError}
                                        paymentSuccessful={() => this.registerFinish("CB")}
                                    />
                                </Elements>
                            </Col>
                            <Col sm={4}/>
                        </Form.Row>
                    </div>

                );
            case "CHECK":
                return (
                    <Form.Row className="align-items-center" style={styles.formWrapper}>
                        <Col sm={2}/>
                        <Col sm={8} style={styles.center}>
                            <Row>
                                <span style={styles.checkInfo}>{t("REGISTER_STEP5_CHECK_INFO")}</span>
                            </Row>
                            <Row>
                                <Col sm={4}/>
                                <Col sm={4}>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        style={styles.submitCheckButton}
                                        onClick={this.submitCheckPayment}
                                    >
                                        {t("REGISTER_STEP5_VALIDATE")}
                                    </Button>
                                </Col>
                                <Col sm={4}/>
                            </Row>
                        </Col>
                        <Col sm={2}/>
                    </Form.Row>
                );
            default:
                return "";
        }
    }
}


const styles = {
    formWrapper: {
        marginTop: "1rem",
    },
    center: {
        textAlign: "center",
    },
    checkInfo: {
        whiteSpace: "pre-line",
    },
    backButton: {
        marginTop: "15rem"
    },
    submitCheckButton: {
        width: "100%",
        marginTop: "1rem"
    }
};

export default withTranslation()(Step5)
