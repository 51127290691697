import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

import translationsEN from "./translations/en.json";
import translationsFR from "./translations/fr.json";
import translationsES from "./translations/es.json";
import translationsDE from "./translations/de.json";

i18n
    .use(detector)
    .init({
        resources: {
            en: {
                translation: translationsEN
            },
            fr: {
                translation: translationsFR
            },
            de: {
                translation: translationsDE
            },
            es: {
                translation: translationsES
            }
        },
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });


export default i18n;
