import * as React from "react";
import {Alert, Button, Col, Container, Image, Row, Spinner, Table} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import * as axios from "axios";
import Constants from "../../constants";
import moment from "moment";

class Bill extends React.Component {

    constructor(props) {
        super(props);

        const currentPath = this.props.location.pathname;

        this.state = {
            bill: currentPath.replace("/bill/", ""),
            billData: null,
            currencySymbol: '',
            noData: false
        }
    }

    componentDidMount() {
        const options = {
            url: Constants.baseUrl + Constants.billRoute + this.state.bill,
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    const currencySymbol = response.data.currency === "EUR" ? "€" : "$";
                    let amountNoVat = response.data.lines.reduce((accumulator, line) => {
                        return accumulator + line.amount;
                    }, 0);
                    amountNoVat = Math.round(amountNoVat * 100) / 100;
                    let vat = response.data.total - amountNoVat;
                    vat = Math.round(vat * 100) / 100;

                    let taxText = "";
                    let hasVat = true;
                    switch (response.data.taxType) {
                        case 'A':
                            taxText = "TVA APPLICABLE";
                            break;
                        case 'B':
                            taxText = "Exonération de TVA, article 283-2 du CGI";
                            hasVat = false;
                            break;
                        case 'C':
                            taxText = "TVA APPLICABLE";
                            break;
                        case 'D':
                            taxText = "TVA non applicable – art. 259-1 du CGI ";
                            hasVat = false;
                            break;
                    }

                    this.setState({
                        billData: response.data,
                        currencySymbol: currencySymbol,
                        amountNoVat: amountNoVat,
                        vat: vat,
                        taxText: taxText,
                        hasVat: hasVat,
                        noData: false
                    });
                } else {
                    this.setState({
                        noData: true
                    })
                }
            })
            .catch(error => {
                this.setState({
                    noData: true
                })
                console.log(error);
            });
    }

    print = () => {
        window.print();
    }

    render() {

        const {t} = this.props;

        return (
            <Container style={{paddingTop: 30}}>
                {
                    this.state.noData ? (
                        <Row className="justify-content-center">
                            <Alert variant="danger" style={{padding: 20}}>
                                <h3>{t("BILL_ERROR")}</h3>
                            </Alert>
                        </Row>
                    ) : null
                }
                {
                    this.state.billData ? (
                        <>
                            <Row className="print-button" style={{marginTop: 50, marginBottom: 50}}>
                                <Col>
                                    <Button variant="primary" size="lg" className="float-right"
                                            onClick={() => this.print()}>
                                        {t("BILL_PRINT")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="align-items-end">
                                <Col>
                                    <Image src="/logo.jpg" fluid style={{marginBottom: 25}}/>
                                    <h4>WOHI SAS</h4>
                                    <p>SIRET : 88178165200014</p>
                                    <p>156 route de Saint-Marcellin</p>
                                    <p>38590 Saint-Etienne-De-Saint-Geoirs</p>
                                    <p>France</p>
                                </Col>
                                <Col/>
                                <Col>
                                    <h5 style={{
                                        paddingBottom: 35
                                    }}
                                    >
                                        Date de facturation
                                        : {moment(this.state.billData.billingDate).format("DD/MM/YYYY")}
                                    </h5>
                                    <h4>Adresse de facturation</h4>
                                    {
                                        this.state.billData.address.map((line) => {
                                            return (
                                                <p key={line}>{line}</p>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                            <Row className="justify-content-center align-items-center"
                                 style={{marginBottom: 50, marginTop: 60}}>
                                <Col/>
                                <Col className="text-center">
                                    <h3>Facture n°{('000000' + this.state.billData.billNumber).slice(-6)}</h3>
                                </Col>
                                <Col className="text-center">
                                    <h5 style={{
                                        border: 'solid',
                                        padding: 5
                                    }}>
                                        {this.state.taxText}
                                    </h5>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Désignation</th>
                                        <th>Quantité</th>
                                        <th>Prix Unitaire HT</th>
                                        {
                                            this.state.hasVat ? (
                                                <th>TVA</th>
                                            ) : null
                                        }
                                        <th>Total HT</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.billData.lines.map((line) => {
                                            return (
                                                <tr key={line.billLineID}>
                                                    <td>{line.designation}</td>
                                                    <td>1</td>
                                                    <td>{line.amount.toFixed(2)} {this.state.currencySymbol}</td>
                                                    {
                                                        this.state.hasVat ? (
                                                            <td>{line.vatRate.toFixed(2)} %</td>
                                                        ) : null
                                                    }
                                                    <td>{line.amount.toFixed(2)} {this.state.currencySymbol}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    </tbody>
                                </Table>

                            </Row>
                            <Row style={{marginTop: 30}} className="align-items-end">
                                <Col>
                                    <Table striped hover>
                                        <tbody>
                                        <tr>
                                            <td>Echéance :</td>
                                            <td>
                                                <span className="float-right"><b>à réception</b></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Devise :</td>
                                            <td>
                                                <span
                                                    className="float-right"><b>{this.state.billData.currency}</b></span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col/>
                                <Col>
                                    <Table striped hover>
                                        <tbody>
                                        <tr>
                                            <td>Total HT</td>
                                            <td>
                                                <span className="float-right">
                                                    {this.state.amountNoVat.toFixed(2)} {this.state.currencySymbol}
                                                </span>
                                            </td>
                                        </tr>
                                        {
                                            this.state.hasVat ? (
                                                <tr>
                                                    <td>Total TVA</td>
                                                    <td>
                                                <span className="float-right">
                                                    {this.state.vat.toFixed(2)} {this.state.currencySymbol}
                                                </span>
                                                    </td>
                                                </tr>
                                            ) : null
                                        }

                                        <tr>
                                            <td>Frais de port</td>
                                            <td>
                                                <span className="float-right">
                                                    0.00 {this.state.currencySymbol}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>
                                                    Total
                                                    {
                                                        this.state.hasVat ? (
                                                            <> TTC</>
                                                        ) : null
                                                    }
                                                </b>
                                            </td>
                                            <td>
                                                <span className="float-right">
                                                    <b>{this.state.billData.total.toFixed(2)} {this.state.currencySymbol}</b>
                                                </span>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center" style={{marginTop: 50}}>
                                    <small>WOHI SAS - CA 1000 € - SIRET 881 781 65200014 - TVA INTRA : FR59881781652 -
                                        156 Route De Saint-Marcellin 38590 Saint-Étienne-de-Saint-Geoirs</small>
                                </Col>
                            </Row>
                        </>
                    ) : !this.state.noData ? (
                        <Row className="justify-content-center">
                            <Spinner animation="grow"/>

                        </Row>
                    ) : null
                }

            </Container>
        )
    }
}

export default withTranslation()(Bill)
