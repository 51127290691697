import * as React from "react";
import {withTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";

class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <Container>
                <Row>
                    <Col>
                        <h1>{t("PP_TITLE")}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p dangerouslySetInnerHTML={{__html: t("PP_UPDATE_TIME")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("PP_TITLE1")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT1")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("PP_TITLE2")}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE2_1")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT2_1")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE2_2")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT2_2")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE2_3")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT2_3")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE2_4")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT2_4")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE2_5")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT2_5")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("PP_TITLE3")}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE3_1")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT3_1")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE3_2")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT3_2")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE3_3")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT3_3")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE3_4")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT3_4")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE3_5")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT3_5")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE3_6")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT3_6")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE3_7")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT3_7")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>{t("PP_TITLE3_8")}</h4>
                        <p dangerouslySetInnerHTML={{__html: t("PP_TXT3_8")}}/>
                    </Col>
                </Row>
                
            </Container>

        )

    }
}

export default withTranslation()(PrivacyPolicy)
