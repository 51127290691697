import * as React from "react";
import {withTranslation} from "react-i18next";
import {Button, Col, Form} from "react-bootstrap";
import * as yup from "yup";
import "../../../utils/yup-phone";
import {Formik} from "formik";

class Step1 extends React.Component {

    constructor(props) {
        super(props);

        this.schema = yup.object({
            location: yup.string().min(2).required(),
            lastName: yup.string().min(2).required(),
            firstName: yup.string().min(2).required(),
            phone1: yup.string().phone().required(),
            phone2: yup.string().phone().notRequired(),
            email: yup.string().email().required(),
        });

        this.next = this.props.next;
        this.updateFields = this.props.updateFields;

        this.state = {
            submitAlreadyClick: false
        }
    }

    handleSubmit = (fields) => {
        this.updateFields(fields);
        this.next();
    }

    clickSubmit = (valid) => {
        if (!valid) {
            this.setState({submitAlreadyClick: true}, this.scrollToFormTop);
        }
    }

    render() {

        const {t} = this.props;

        return (
            <Formik
                validationSchema={this.schema}
                onSubmit={this.handleSubmit}
                initialValues={{}}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP1_LABEL_LOCATION")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="location"
                                    value={values.location || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP1_PLACEHOLDER_LOCATION")}
                                    isValid={this.state.submitAlreadyClick && !errors.location}
                                    isInvalid={this.state.submitAlreadyClick && errors.location}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP1_INVALID_LOCATION")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}/>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP1_LABEL_LASTNAME")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP1_PLACEHOLDER_LASTNAME")}
                                    isValid={this.state.submitAlreadyClick && !errors.lastName}
                                    isInvalid={this.state.submitAlreadyClick && errors.lastName}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP1_INVALID_LASTNAME")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP1_LABEL_FIRSTNAME")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP1_PLACEHOLDER_FIRSTNAME")}
                                    isValid={this.state.submitAlreadyClick && !errors.firstName}
                                    isInvalid={this.state.submitAlreadyClick && errors.firstName}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP1_INVALID_FIRSTNAME")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP1_LABEL_PHONE1")}</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="phone1"
                                    value={values.phone1 || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP1_PLACEHOLDER_PHONE1")}
                                    isValid={this.state.submitAlreadyClick && !errors.phone1}
                                    isInvalid={this.state.submitAlreadyClick && errors.phone1}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP1_INVALID_PHONE1")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP1_LABEL_PHONE2")}</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="phone2"
                                    value={values.phone2 || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP1_PLACEHOLDER_PHONE2")}
                                    isValid={this.state.submitAlreadyClick && !errors.phone2}
                                    isInvalid={this.state.submitAlreadyClick && errors.phone2}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP1_INVALID_PHONE2")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP1_LABEL_EMAIL")}</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={values.email || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP1_PLACEHOLDER_EMAIL")}
                                    isValid={this.state.submitAlreadyClick && !errors.email}
                                    isInvalid={this.state.submitAlreadyClick && errors.email}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP1_INVALID_EMAIL")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}/>
                        </Form.Row>
                        <Form.Row style={styles.row}>
                            <Col sm>
                                <Button variant="primary" type="submit" className="float-right"
                                        onClick={() => this.clickSubmit(isValid)}>
                                    {t("REGISTER_STEP1_NEXT")}
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        );
    }
}

const styles = {
    formWrapper: {
        marginTop: "1rem",
    }
};

export default withTranslation()(Step1)
