import * as React from "react";
import RectDropzone from 'react-dropzone';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "react-bootstrap";

class Dropzone extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            loading: false
        };

        this.height = this.props.height;
        this.multiple = this.props.multiple;
        this.accept = this.props.accept;
        this.text = this.props.text;
        this.onDropParent = this.props.onDrop;
        this.onRemoveParent = this.props.onRemove;
    }

    dropzoneStyle() {
        return {
            height: this.props.height,
            borderStyle: "dashed",
            borderWidth: "0.1rem",
            borderRadius: "1rem",
            backgroundColor: "rgba(215,215,215,0.40)",
            textAlign: "center",
            lineHeight: this.props.height,
            verticalAlign: "middle"
        }
    }

    onDrop = (acceptedFiles) => {
        this.setState({
            loading: true
        })
        this.onDropParent(acceptedFiles).then((names) => {
            const filesWithHash = acceptedFiles.map((file, idx) => Object.assign(file, {
                preview: URL.createObjectURL(file),
                hash: names[idx]
            }));

            this.setState({
                files: [...this.state.files, ...filesWithHash],
                loading: false
            });
        });
    }

    deleteImg = (hash, idx) => {
        const files = this.state.files;
        files.splice(idx, 1);

        this.setState({
            files: files
        });

        this.onRemoveParent(hash);
    }

    render() {
        const thumbs = this.state.files.map((file, idx) => (
            <div style={styles.thumb} key={file.name + idx}>
                <div style={styles.thumbInner}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        color="#E12800"
                        style={styles.closeIcon}
                        onClick={() => this.deleteImg(file.hash, idx)}
                    />
                    <img
                        src={file.preview}
                        style={styles.img}
                        alt={"image n°" + idx}
                    />
                </div>
            </div>
        ));

        return (
            <RectDropzone
                accept={this.accept}
                onDrop={this.onDrop}
                multiple={this.multiple}
                disabled={this.multiple === false && this.state.files.length === 1}
            >
                {({getRootProps, getInputProps}) => (
                    <section>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p style={this.dropzoneStyle()}>{this.text}</p>
                        </div>
                        <aside style={styles.thumbsContainer}>
                            {thumbs}
                            {
                                this.state.loading ? (
                                    <Spinner animation="grow" size="lg" variant="primary"/>
                                ) : null
                            }
                        </aside>
                    </section>
                )}
            </RectDropzone>
        )
    }

}

const styles = {
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box'
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden'
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%',
        marginLeft: -20
    },
    closeIcon: {
        width: 20,
        fontSize: 20,
        zIndex: 5,
        marginTop: 2,
        backgroundColor: "rgba(255,255,255,0.80)"
    },

}

export default Dropzone
