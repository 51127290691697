import * as React from "react";
import {withTranslation} from "react-i18next";
import {Badge, Button, Col, Form} from "react-bootstrap";
import {Formik} from "formik";
import * as yup from "yup";
import * as axios from "axios";
import {Map, Marker, TileLayer} from "react-leaflet";
import Constants from "../../../constants";
import i18next from "i18next";

class Step2 extends React.Component {

    constructor(props) {
        super(props);

        this.prev = this.props.prev;
        this.next = this.props.next;
        this.updateFields = this.props.updateFields;

        this.activities = ['MUSEUM', 'HISTORICAL_SITE', 'RELIGIOUS_BUILDING', 'MONUMENT', 'CITY', 'OTHER'];

        this.schema = yup.object({
            activity: yup.string().oneOf(this.activities).required(),
            wifiNetwork: yup.string().min(2).notRequired(),
            wifiPass: yup.string().min(2).notRequired(),
            addressStreet: yup.string().min(2).required(),
            addressZipCode: yup.string().min(2).required(),
            addressCity: yup.string().min(2).required(),
            addressCountry: yup.string().length(2).required(),
            addressLat: yup.number().min(-90).max(90).required(),
            addressLon: yup.number().min(-180).max(180).required(),
            billingAddressName: yup.string().min(2).required(),
            tvaIntra: yup.string().min(10),
            billingAddressStreet: yup.string().min(2).required(),
            billingAddressZipCode: yup.string().min(2).required(),
            billingAddressCity: yup.string().min(2).required(),
            billingAddressCountry: yup.string().length(2).required(),
        });

        this.state = {
            submitAlreadyClick: false,
            marker: undefined,
            mapZoom: 15,
            countries: {}
        }

        this.latLonTimeout = null;
        this.getLatLonTimeout = null;
        this.lastAddress = "";
        this.lastLatLng = "";
        this.setFieldValue = null;
    }

    getLanguage = () => i18next.language || window.localStorage.i18nextLng;

    componentDidMount() {
        // Load countries
        const options = {
            url: Constants.baseUrl + Constants.countriesRoute,
            method: 'GET',
            headers: {'Accept': 'application/json'}
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        countries: response.data
                    });
                    let currentLangage = this.getLanguage().toUpperCase();
                    if (!response.data[currentLangage]) {
                        currentLangage = "FR";
                    }
                    this.setFieldValue("addressCountry", currentLangage);
                    this.setFieldValue("billingAddressCountry", currentLangage);
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    handleSubmit = (fields) => {
        this.updateFields(fields);
        this.next();
    }

    clickSubmit = (valid) => {
        if (!valid) {
            this.setState({submitAlreadyClick: true}, this.scrollToFormTop);
        }
    }

    back = () => {
        this.prev();
    }

    getLatLonFromAddress(address) {
        const options = {
            url: Constants.baseUrl + Constants.latlngRoute,
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {
                'address': address
            }
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    const lat = response.data.location.lat;
                    const lng = response.data.location.lng;
                    this.setFieldValue("addressLat", lat);
                    this.setFieldValue("addressLon", lng);
                }
            })
            .catch(error => {
                console.error(error);
            })


    }

    updtValues(values, setFieldValue) {
        if (this.setFieldValue === null) {
            this.setFieldValue = setFieldValue;
        }

        if (values.addressStreet && values.addressZipCode && values.addressCity && values.addressCountry) {
            const currentAddr = values.addressStreet + " " + values.addressZipCode + " " + values.addressCity + " " + values.addressCountry;
            if (this.lastAddress !== currentAddr) {
                this.lastAddress = currentAddr;
                clearTimeout(this.getLatLonTimeout);
                this.getLatLonTimeout = setTimeout(() => {
                    this.getLatLonFromAddress(this.lastAddress);
                }, 1000);
            }
        }

        if (values.addressLat && values.addressLon) {
            const currentLatLng = values.addressLat + " " + values.addressLon;
            if (this.lastLatLng !== currentLatLng) {
                this.lastLatLng = currentLatLng;
                clearTimeout(this.latLonTimeout);
                const lat = parseFloat(values.addressLat);
                const lng = parseFloat(values.addressLon);
                this.latLonTimeout = setTimeout(() => {
                    this.setState({marker: [lat, lng]});
                }, 500);
            }
        }
    }

    render() {

        const {t} = this.props;

        return (
            <Formik
                validationSchema={this.schema}
                onSubmit={this.handleSubmit}
                initialValues={{}}
            >
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      setFieldValue,
                      isValid,
                      errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        {this.updtValues(values, setFieldValue)}
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ACTIVITY")}</Form.Label>
                                {this.activities.map((activityType) => (
                                    <Form.Check
                                        key={activityType}
                                        type="radio"
                                        label={t("REGISTER_STEP2_LABEL_" + activityType)}
                                        checked={values.activity === activityType ? "checked" : ""}
                                        onChange={() => {
                                            setFieldValue("activity", activityType);
                                        }}
                                        isValid={this.state.submitAlreadyClick && !errors.activity}
                                        isInvalid={this.state.submitAlreadyClick && errors.activity}
                                    />
                                ))}
                            </Col>
                            <Col sm style={styles.formWrapper}/>
                        </Form.Row>
                        {
                            values.activity === "OTHER" ? (
                                <Form.Row>
                                    <Col sm style={{...styles.formWrapper, marginLeft: 30}}>
                                        <Form.Control
                                            type="text"
                                            name="activityOther"
                                            value={values.activityOther || ''}
                                            onChange={handleChange}
                                            placeholder={t("REGISTER_STEP2_PLACEHOLDER_OTHER")}
                                            isValid={this.state.submitAlreadyClick && !errors.activityOther}
                                            isInvalid={this.state.submitAlreadyClick && errors.activityOther}
                                        />
                                    </Col>
                                    <Col sm style={styles.formWrapper}/>
                                </Form.Row>
                            ) : null
                        }
                        <Form.Row className="align-items-center" style={styles.infoRow}>
                            <span style={styles.info2}>
                                <Badge pill variant="primary" style={styles.info}>
                                    i
                                </Badge>
                            </span>
                            <Col>
                                <span dangerouslySetInnerHTML={{__html: t("REGISTER_STEP2_WIFI_INFO")}}/>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_WIFI_NETWORK")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="wifiNetwork"
                                    value={values.wifiNetwork || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_PLACEHOLDER_WIFI_NETWORK")}
                                    isValid={this.state.submitAlreadyClick && !errors.wifiNetwork}
                                    isInvalid={this.state.submitAlreadyClick && errors.wifiNetwork}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_WIFI_NETWORK")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_WIFI_PASS")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="wifiPass"
                                    value={values.wifiPass || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_PLACEHOLDER_WIFI_PASS")}
                                    isValid={this.state.submitAlreadyClick && !errors.wifiPass}
                                    isInvalid={this.state.submitAlreadyClick && errors.wifiPass}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_WIFI_PASS")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm/>
                            <Col sm style={styles.formWrapper}>
                                <hr/>
                            </Col>
                            <Col sm/>
                        </Form.Row>
                        <Form.Row>
                            <Col sm>
                                <h3>{t("REGISTER_STEP2_ADDRESS")}</h3>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ADDR_STREET")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="addressStreet"
                                    value={values.addressStreet || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_PLACEHOLDER_ADDR_STREET")}
                                    isValid={this.state.submitAlreadyClick && !errors.addressStreet}
                                    isInvalid={this.state.submitAlreadyClick && errors.addressStreet}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_ADDR_STREET")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={4} style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ADDR_ZIP")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="addressZipCode"
                                    value={values.addressZipCode || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_PLACEHOLDER_ADDR_ZIP")}
                                    isValid={this.state.submitAlreadyClick && !errors.addressZipCode}
                                    isInvalid={this.state.submitAlreadyClick && errors.addressZipCode}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_ADDR_ZIP")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ADDR_CITY")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="addressCity"
                                    value={values.addressCity || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_PLACEHOLDER_ADDR_CITY")}
                                    isValid={this.state.submitAlreadyClick && !errors.addressCity}
                                    isInvalid={this.state.submitAlreadyClick && errors.addressCity}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_ADDR_CITY")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={4} style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ADDR_COUNTRY")}</Form.Label>
                                <Form.Control
                                    as="select"
                                    type="text"
                                    name="addressCountry"
                                    value={values.addressCountry || ''}
                                    onChange={handleChange}
                                    isValid={this.state.submitAlreadyClick && !errors.addressCountry}
                                    isInvalid={this.state.submitAlreadyClick && errors.addressCountry}
                                >
                                    {Object.keys(this.state.countries).map((keyName, i) => (
                                        <option key={i} value={keyName}>{this.state.countries[keyName]}</option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_ADDR_COUNTRY")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row className="align-items-center" style={styles.infoRow}>
                            <span style={styles.info2}>
                                <Badge pill variant="primary" style={styles.info}>
                                    i
                                </Badge>
                            </span>
                            <Col>
                                <span dangerouslySetInnerHTML={{__html: t("REGISTER_STEP2_MAP_INFO")}}/>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_LAT")}</Form.Label>
                                <Form.Control
                                    type="number"
                                    min="-90"
                                    max="90"
                                    step="0.000001"
                                    name="addressLat"
                                    value={values.addressLat || ''}
                                    onChange={handleChange}
                                    isValid={this.state.submitAlreadyClick && !errors.addressLat}
                                    isInvalid={this.state.submitAlreadyClick && errors.addressLat}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_LAT")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_LON")}</Form.Label>
                                <Form.Control
                                    type="number"
                                    min="-180"
                                    max="180"
                                    step="0.000001"
                                    name="addressLon"
                                    value={values.addressLon || ''}
                                    onChange={handleChange}
                                    isValid={this.state.submitAlreadyClick && !errors.addressLon}
                                    isInvalid={this.state.submitAlreadyClick && errors.addressLon}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_LON")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row style={styles.formWrapper}>
                            <Col sm>
                                {this.renderMap()}
                            </Col>
                        </Form.Row>
                        <Form.Row style={styles.formWrapper}>
                            <Col sm style={styles.formWrapper}>
                                <h3>{t("REGISTER_STEP2_BILLING_ADDRESS")}</h3>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_BILLING_ADDRESS_NAME_LABEL")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="billingAddressName"
                                    value={values.billingAddressName || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_BILLING_ADDRESS_NAME_PLACEHOLDER")}
                                    isValid={this.state.submitAlreadyClick && !errors.billingAddressName}
                                    isInvalid={this.state.submitAlreadyClick && errors.billingAddressName}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_BILLING_ADDRESS_NAME_INVALID")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_BILLING_TVA_INTRA_LABEL")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="tvaIntra"
                                    value={values.tvaIntra || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_BILLING_TVA_INTRA_PLACEHOLDER")}
                                    isValid={this.state.submitAlreadyClick && !errors.tvaIntra}
                                    isInvalid={this.state.submitAlreadyClick && errors.tvaIntra}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_BILLING_TVA_INTRA_INVALID")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ADDR_STREET")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="billingAddressStreet"
                                    value={values.billingAddressStreet || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_PLACEHOLDER_ADDR_STREET")}
                                    isValid={this.state.submitAlreadyClick && !errors.billingAddressStreet}
                                    isInvalid={this.state.submitAlreadyClick && errors.billingAddressStreet}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_ADDR_STREET")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={4} style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ADDR_ZIP")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="billingAddressZipCode"
                                    value={values.billingAddressZipCode || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_PLACEHOLDER_ADDR_ZIP")}
                                    isValid={this.state.submitAlreadyClick && !errors.billingAddressZipCode}
                                    isInvalid={this.state.submitAlreadyClick && errors.billingAddressZipCode}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_ADDR_ZIP")}</Form.Control.Feedback>
                            </Col>
                            <Col sm style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ADDR_CITY")}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="billingAddressCity"
                                    value={values.billingAddressCity || ''}
                                    onChange={handleChange}
                                    placeholder={t("REGISTER_STEP2_PLACEHOLDER_ADDR_CITY")}
                                    isValid={this.state.submitAlreadyClick && !errors.billingAddressCity}
                                    isInvalid={this.state.submitAlreadyClick && errors.billingAddressCity}
                                />
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_ADDR_CITY")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm={4} style={styles.formWrapper}>
                                <Form.Label>{t("REGISTER_STEP2_LABEL_ADDR_COUNTRY")}</Form.Label>
                                <Form.Control
                                    as="select"
                                    type="text"
                                    name="billingAddressCountry"
                                    value={values.billingAddressCountry || ''}
                                    onChange={handleChange}
                                    isValid={this.state.submitAlreadyClick && !errors.billingAddressCountry}
                                    isInvalid={this.state.submitAlreadyClick && errors.billingAddressCountry}
                                >
                                    {Object.keys(this.state.countries).map((keyName, i) => (
                                        <option key={i}
                                                value={keyName}>{this.state.countries[keyName]}</option>
                                    ))}
                                </Form.Control>
                                <Form.Control.Feedback
                                    type="invalid">{t("REGISTER_STEP2_INVALID_ADDR_COUNTRY")}</Form.Control.Feedback>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm style={styles.formWrapper}>
                                <Button variant="primary" onClick={() => this.back()}>
                                    {t("REGISTER_STEP2_PREVIOUS")}
                                </Button>
                                <Button variant="primary" type="submit" className="float-right"
                                        onClick={() => this.clickSubmit(isValid)}>
                                    {t("REGISTER_STEP2_NEXT")}
                                </Button>
                            </Col>
                        </Form.Row>
                    </Form>
                )}
            </Formik>
        );
    }

    zoomUpdate = (event) => {
        this.setState({
            mapZoom: event.target._zoom
        });
    }

    markerUpdate = (event) => {
        const lat = event.target._latlng.lat;
        const lng = event.target._latlng.lng;
        this.setFieldValue("addressLat", lat);
        this.setFieldValue("addressLon", lng);
    }

    renderMap() {
        const initialPosition = [48.858276, 2.294067]

        if (this.state.marker !== undefined) {
            return (
                <Map
                    center={this.state.marker || initialPosition}
                    onZoomEnd={this.zoomUpdate}
                    zoom={this.state.mapZoom}
                    style={styles.map}
                    scrollWheelZoom={false}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                    <Marker
                        position={this.state.marker}
                        draggable={true}
                        onDragend={this.markerUpdate}
                    />
                </Map>
            )
        }
    }
}

const styles = {
    formWrapper: {
        marginTop: "1rem",
    },
    info: {
        fontSize: "1rem",
        marginRight: "0.8rem"
    },
    info2: {
        fontSize: "1rem",
        width: "2rem"
    },
    map: {
        height: "30vh",
        width: "100%",
    },
    infoRow: {
        marginTop: "2rem",
        marginBottom: "2rem",
    }
};

export default withTranslation()(Step2)
