import * as React from "react";
import {withTranslation} from "react-i18next";
import {Col, Container, Row} from "react-bootstrap";

class LegalNotice extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <Container>
                <Row>
                    <Col>
                        <h1>{t("LEGAL_NOTICE_TITLE")}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("LEGAL_NOTICE_TITLE_1")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("LEGAL_NOTICE_TXT_1")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("LEGAL_NOTICE_TITLE_2")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("LEGAL_NOTICE_TXT_2")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("LEGAL_NOTICE_TITLE_3")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("LEGAL_NOTICE_TXT_3")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("LEGAL_NOTICE_TITLE_4")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("LEGAL_NOTICE_TXT_4")}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3>{t("LEGAL_NOTICE_TITLE_5")}</h3>
                        <p dangerouslySetInnerHTML={{__html: t("LEGAL_NOTICE_TXT_5")}}/>
                    </Col>
                </Row>
            </Container>

        )

    }
}

export default withTranslation()(LegalNotice)
