export default {
    baseUrl: "https://wohi-app.com",
    contactRoute: "/api/core/contact",
    countriesRoute: "/api/core/utils/countries",
    latlngRoute: "/api/core/utils/latlng",
    pricesRoute: "/api/core/price/owner",
    uploadLocationRoute: "/api/locregister/images/location",
    uploadADSRoute: "/api/locregister/images/ads",
    submitFormStepOneToFour: "/api/locregister/location/",
    getTotalPriceRoute: "/api/locregister/location/price/",
    createPaymentIntentRoute: "/api/locregister/location/payment/",
    resetPasswordRoute: "/api/user/reset/",
    billRoute: "/api/core/payment/bill/",
    recaptchakey: "6LejGdEZAAAAAMXxrJ1Edq4zll1FYp-wToKbLSmA",
    stripePublicKey: "pk_test_qo25GjnYFhenZwqEQ1WPR70k00lllh1DoV",

    currencySymbol: {
        EUR: "€",
        USD: "$"
    },

    iosStoreUrl: "https://apps.apple.com/fr/app/id1528198263",
    androidStoreUrl: "https://play.google.com/store/apps/details?id=com.wohiapp",
}
