import * as React from "react";
import {withTranslation} from "react-i18next";
import {Badge, Button, Col, Form, Spinner} from "react-bootstrap";
import * as yup from "yup";
import {Formik} from "formik";
import Dropzone from "../utils/dropzone";
import * as axios from "axios";
import Constants from "../../../constants";

class Step4 extends React.Component {

    constructor(props) {
        super(props);

        this.prev = this.props.prev;
        this.next = this.props.next;
        this.sumbit = this.props.sumbit;
        this.updateFields = this.props.updateFields;

        this.schema = yup.object({
            description: yup.string().min(10).required(),
            imagesLocation: yup.number().min(1).required(),
            imagesAds: yup.string().min(2),
        });

        this.adsOption = {
            ON: true,
            OFF: false
        }

        this.state = {
            submitAlreadyClick: false,
            imagesAds: "",
            imagesLocation: [],
            sending: false,
            adsOption: false
        };

    }

    back = () => {
        this.prev();
    }

    handleSubmit = (fields) => {
        const step4data = {
            locationDescription: fields.description,
            imageAds: this.state.adsOption ? fields.imagesAds : "",
            imagesLocation: this.state.imagesLocation
        };

        this.updateFields(step4data);

        this.setState({
            sending: true
        });

        this.sumbit(
            () => {
                //OK Callback
                this.setState({
                    sending: false
                });
                this.next();
            }, () => {
                //KO Callback
                this.setState({
                    sending: false
                });
                //TODO modal
                console.log("ERROR");
            }
        );
    }

    clickSubmit = (valid) => {
        if (!valid) {
            this.setState({submitAlreadyClick: true});
        }
    }

    onDropAds = (files, setFieldValue) => {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('file', files[0]);

            axios.put(Constants.baseUrl + Constants.uploadADSRoute, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    if (response.status === 201) {
                        this.setState({
                            imagesAds: response.data
                        }, () => setFieldValue('imagesAds', response.data));
                        resolve([response.data]);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    onDropLocations = (files, setFieldValues) => {
        return new Promise((resolve, reject) => {
            let formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }

            axios
                .put(Constants.baseUrl + Constants.uploadLocationRoute, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.status === 201) {
                        this.setState({
                            imagesLocation: [...this.state.imagesLocation, ...response.data]
                        }, () => setFieldValues("imagesLocation", this.state.imagesLocation.length));
                        resolve(response.data);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    onRemoveLocation = (hash, setFieldValues) => {
        let images = this.state.imagesLocation;
        images.splice(images.indexOf(hash), 1);

        this.setState({
            imagesLocation: images
        }, () => setFieldValues("imagesLocation", this.state.imagesLocation.length));
    }

    onRemoveAds = (hash, setFieldValues) => {
        this.setState({
            imagesAds: ""
        }, () => setFieldValues("imagesAds", ""));
    }

    render() {

        const {t} = this.props;

        return (
            <div>
                <Formik
                    validationSchema={this.schema}
                    onSubmit={this.handleSubmit}
                    initialValues={{
                        imagesLocation: 0,
                        imagesAds: ""
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          setFieldValue,
                          isValid,
                          errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm>
                                    <h3>{t("REGISTER_STEP4_TITLE_APP_PRESENCE")}</h3>
                                </Col>
                            </Form.Row>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm>
                                    <Form.Label>{t("REGISTER_STEP4_LABEL_DROPZONE_IMG")}</Form.Label>
                                </Col>
                            </Form.Row>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm>
                                    <Dropzone
                                        multiple={true}
                                        height="15vh"
                                        accept="image/jpeg, image/png"
                                        text={t("REGISTER_STEP4_PLACEHOLDER_DROPZONE_IMG")}
                                        onDrop={
                                            (files) => this.onDropLocations(files, setFieldValue)
                                        }
                                        onRemove={
                                            (hash) => this.onRemoveLocation(hash, setFieldValue)
                                        }
                                    />
                                    <Form.Control
                                        name="imagesLocation"
                                        type="hidden"
                                        value={values.imagesLocation}
                                        onChange={handleChange}
                                        isValid={this.state.submitAlreadyClick && !errors.imagesLocation}
                                        isInvalid={this.state.submitAlreadyClick && errors.imagesLocation}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{t("REGISTER_STEP4_INVALID_DROPZONE_IMG")}</Form.Control.Feedback>
                                </Col>
                            </Form.Row>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm>
                                    <Form.Label>{t("REGISTER_STEP4_LABEL_DESCR")}</Form.Label>
                                    <Form.Control
                                        name="description"
                                        as="textarea"
                                        rows="10"
                                        value={values.description || ''}
                                        onChange={handleChange}
                                        placeholder={t("REGISTER_STEP4_PLACEHOLDER_DESCR")}
                                        isValid={this.state.submitAlreadyClick && !errors.description}
                                        isInvalid={this.state.submitAlreadyClick && errors.description}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{t("REGISTER_STEP4_INVALID_DESCR")}</Form.Control.Feedback>
                                </Col>
                            </Form.Row>
                            <Form.Row style={styles.infoRow}>
                                <span style={styles.info2}>
                                    <Badge pill variant="primary" style={styles.info}>
                                        i
                                    </Badge>
                                </span>
                                <Col>
                                    <span dangerouslySetInnerHTML={{__html: t("REGISTER_STEP4_ADS_INFO")}}/>
                                </Col>
                            </Form.Row>
                            <Form.Row style={styles.formWrapper}>
                                <Col sm={7}>
                                    <Col sm>
                                        <Form.Label>{t("REGISTER_STEP4_ADS_LABEL")}</Form.Label>
                                        {Object.keys(this.adsOption).map((opt) => (
                                            <Form.Check
                                                key={opt}
                                                type="radio"
                                                label={t("REGISTER_STEP4_ADS_" + opt)}
                                                checked={this.state.adsOption === this.adsOption[opt] ? "checked" : ""}
                                                onChange={() => {
                                                    this.setState({
                                                        adsOption: this.adsOption[opt]
                                                    });
                                                }}
                                            />
                                        ))}
                                        {console.log(this.state.adsOption)}
                                        <div style={{
                                            ...styles.dropzone,
                                            display: this.state.adsOption ? 'block' : 'none'
                                        }}>
                                            <Form.Label>{t("REGISTER_STEP4_LABEL_DROPZONE_ADS")}</Form.Label>
                                            <Dropzone
                                                multiple={false}
                                                height="35vh"
                                                accept="image/jpeg, image/png"
                                                text={t("REGISTER_STEP4_PLACEHOLDER_DROPZONE_ADS")}
                                                onDrop={
                                                    (files) => this.onDropAds(files, setFieldValue)
                                                }
                                                onRemove={
                                                    (hash) => this.onRemoveAds(hash, setFieldValue)
                                                }
                                            />
                                            <Form.Control
                                                name="imagesAds"
                                                type="hidden"
                                                value={values.imagesAds}
                                                onChange={handleChange}
                                                isValid={this.state.submitAlreadyClick && !errors.imagesAds}
                                                isInvalid={this.state.submitAlreadyClick && errors.imagesAds}
                                            />
                                            <Form.Control.Feedback
                                                type="invalid">{t("REGISTER_STEP4_INVALID_DROPZONE_IMG")}</Form.Control.Feedback>
                                        </div>

                                    </Col>
                                </Col>
                                <Col sm>
                                    TODO ADD SCREENSHOT
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Col sm style={styles.formWrapper}>
                                    <Button variant="primary" onClick={() => this.back()}>
                                        {t("REGISTER_STEP4_PREV")}
                                    </Button>
                                    <Button variant="primary" type="submit" className="float-right"
                                            onClick={() => this.clickSubmit(isValid)}
                                            disabled={this.state.sending ? "disabled" : null}>
                                        {
                                            this.state.sending === true ? (
                                                <Spinner animation="grow" size="sm" variant="light"
                                                         style={styles.spinner}/>
                                            ) : null
                                        }
                                        {t("REGISTER_STEP4_NEXT")}
                                    </Button>
                                </Col>
                            </Form.Row>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}


const styles = {
    formWrapper: {
        marginTop: "1rem",
    },
    info: {
        fontSize: "1rem",
        marginRight: "0.8rem"
    },
    info2: {
        fontSize: "1rem",
        width: "2rem"
    },
    spinner: {
        marginRight: "1rem",
    },
    infoRow: {
        marginTop: "2rem",
        marginBottom: "3rem"
    },
    dropzone: {
        marginTop: "2rem",
    }
};

export default withTranslation()(Step4)
