import React, {Suspense} from 'react';
import Main from "./components/main";
import i18n from "i18next";
import {I18nextProvider} from "react-i18next";
import "./i18n.js";

function App() {

    return (
        <I18nextProvider i18n={i18n}>
            <Suspense fallback="loading">
                <Main/>
            </Suspense>
        </I18nextProvider>
    );
}

export default App;
