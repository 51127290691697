import * as React from "react";
import {withTranslation} from "react-i18next";
import {Alert, Col, Container, Image, Row} from "react-bootstrap";
import Colors from "../../style/Colors";
import {Beforeunload} from 'react-beforeunload';
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Scroll from "react-scroll";
import Step5 from "./step5";
import Constants from "../../constants";
import * as utils from "../../utils/language";
import * as axios from "axios";
import museum from "../../images/museum.jpg";


class Register extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeTab: 1,
            step5reload: false,
            finish: false,
            paymentMethod: ""
        }

        this.fields = {};
    }

    updateFields = (fields) => {
        this.fields = {...this.fields, ...fields};

        console.log("Fields :");
        console.log(this.fields);
    };

    getFieldValue = (key) => {
        return this.fields[key];
    }

    scrollToFormNav() {
        Scroll.scroller.scrollTo('form-nav', {
            duration: 1000,
            smooth: true,
            offset: -15
        })
    }

    selectTab = (tab) => {
        if (tab < this.state.activeTab) {
            this.setState({
                activeTab: tab
            });
            this.scrollToFormNav();
        }
    };

    nextTab = () => {
        const next = this.state.activeTab + 1;
        if (next === 5) {
            this.setState({
                step5reload: true
            });
        }
        if (next < 6) {
            this.setState({
                activeTab: next
            });
            this.scrollToFormNav();
        }
    };

    previousTab = () => {
        const prev = this.state.activeTab - 1;
        if (prev > 0) {
            this.setState({
                activeTab: prev
            });
            this.scrollToFormNav();
        }
    };

    submitStepOneToFour = (callbackOK, callbackKO) => {

        this.updateFields({sourceLanguage: utils.getLanguage().substr(0, 2).toUpperCase()});

        let url;
        if ("ref" in this.fields) {
            //Update in back
            url = Constants.baseUrl + Constants.submitFormStepOneToFour + "/" + this.fields.ref;
        } else {
            //First submission
            url = Constants.baseUrl + Constants.submitFormStepOneToFour;
        }

        const options = {
            url: url,
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: this.fields
        };

        axios(options)
            .then(response => {
                if (response.status === 200) {
                    callbackOK();
                } else if (response.status === 201) {
                    this.updateFields({ref: response.data});
                    callbackOK();
                }
            })
            .catch(error => {
                callbackKO();
            });

    };

    step5isReloaded = () => {
        this.setState({
            step5reload: false
        })
    };

    registerFinish = (paymentMethod) => {
        this.setState({
            finish: true,
            paymentMethod: paymentMethod
        });
    }

    styleDot(step) {
        if (this.state.activeTab === step) {
            return styles.fullDot;
        } else {
            return styles.dot;
        }
    }

    styleTab(step) {
        if (this.state.activeTab !== step) {
            return styles.hidden;
        }
    }

    render() {

        const {t} = this.props;

        return (
            <Container>
                {
                    this.state.finish ? (
                        <div style={styles.successMsg}>
                            <Row style={styles.formWrapper}>
                                <Col sm style={styles.center}>
                                    <Alert variant="secondary">
                                        <h1>{t("REGISTER_SUCCESS_HEADER")}</h1>
                                        <span dangerouslySetInnerHTML={{__html: t("REGISTER_SUCCESS_MSG")}}/>
                                    </Alert>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div>
                            {
                                this.state.activeTab !== 1 ? (
                                    <Beforeunload onBeforeunload={() => t("REGISTER_ALERT_BEFORE_QUIT")}/>
                                ) : null
                            }
                            <Row className="justify-content-center" style={styles.head}>
                                <Col md={3} style={{marginBottom: 20}}>
                                    <Image src={museum} fluid/>
                                </Col>
                                <Col md={9}>
                                    <p dangerouslySetInnerHTML={{__html: t("REGISTER_HEADER_FIRSTLINE")}}/>
                                    <ul>
                                        <li dangerouslySetInnerHTML={{__html: t("REGISTER_HEADER_BULLET_1")}}/>
                                        <li dangerouslySetInnerHTML={{__html: t("REGISTER_HEADER_BULLET_2")}}/>
                                        <li dangerouslySetInnerHTML={{__html: t("REGISTER_HEADER_BULLET_3")}}/>
                                    </ul>
                                    <p dangerouslySetInnerHTML={{__html: t("REGISTER_HEADER_END")}}/>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={2}>
                                    <hr/>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm="auto">
                                    <h1>{t("REGISTER_JOIN_US")}</h1>
                                </Col>
                            </Row>
                            <Scroll.Element name="form-nav">
                                <Row className="justify-content-center" style={styles.dotNav}>
                                    <span onClick={() => this.selectTab(1)} style={this.styleDot(1)}/>
                                    <span onClick={() => this.selectTab(2)} style={this.styleDot(2)}/>
                                    <span onClick={() => this.selectTab(3)} style={this.styleDot(3)}/>
                                    <span onClick={() => this.selectTab(4)} style={this.styleDot(4)}/>
                                    <span onClick={() => this.selectTab(5)} style={this.styleDot(5)}/>
                                </Row>
                            </Scroll.Element>
                            <Row className="justify-content-center">
                                <Col style={this.styleTab(1)} sm>
                                    <Step1 next={this.nextTab} updateFields={this.updateFields}/>
                                </Col>
                                <Col style={this.styleTab(2)} sm>
                                    <Step2 next={this.nextTab} prev={this.previousTab}
                                           updateFields={this.updateFields}/>
                                </Col>
                                <Col style={this.styleTab(3)} sm>
                                    <Step3 next={this.nextTab} prev={this.previousTab}
                                           updateFields={this.updateFields}/>
                                </Col>
                                <Col style={this.styleTab(4)} sm>
                                    <Step4 sumbit={this.submitStepOneToFour} next={this.nextTab}
                                           prev={this.previousTab}
                                           updateFields={this.updateFields}/>
                                </Col>
                                <Col style={this.styleTab(5)} sm>
                                    <Step5
                                        prev={this.previousTab}
                                        updateFields={this.updateFields}
                                        getFieldValue={this.getFieldValue}
                                        reload={this.state.step5reload}
                                        callbackReload={this.step5isReloaded}
                                        registerFinish={this.registerFinish}
                                    />
                                </Col>
                            </Row>
                        </div>
                    )
                }
            </Container>
        );
    }
}

const styles = {
    head: {
        marginTop: "2rem",
    },
    fullDot: {
        height: "1.2rem",
        width: "1.2rem",
        backgroundColor: Colors.main,
        borderRadius: "50%",
        display: "inline-block",
        margin: "5px"
    },
    dot: {
        height: "1.2rem",
        width: "1.2rem",
        borderRadius: "50%",
        border: "solid",
        borderColor: Colors.main,
        display: "inline-block",
        margin: "5px"
    },
    dotNav: {
        marginTop: "2rem",
        marginBottom: "2rem",
    },
    hidden: {
        display: "none",
    },
    center: {
        textAlign: "center",
    },
    successMsg: {
        marginTop: "3rem",
    }
}

export default withTranslation()(Register)
